import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify.js";
// 加载全局css
import "@/styles/global/common.scss";
import "@/styles/global/card.scss";
import "@/styles/global/datatable.scss";
import globalEventService from "@/utils/globalEventService";

Vue.config.productionTip = false;
Vue.use(globalEventService);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default () => {
  window.onload = () => {
    window.document.onkeydown = () => {
      $_renewActionTime();
    };
    window.document.onmousedown = () => {
      $_renewActionTime();
    };
  };
};

function $_renewActionTime() {
  sessionStorage.setItem("lastActionTime", `${new Date().getTime()}`);
}

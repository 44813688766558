import { buildDynamicRoutes, staticRoutes } from "@/router/appRoutes";
import store from "@/store";

function $_routeAlreadyExist(route, existingRoutes) {
  if (existingRoutes && existingRoutes.length > 0) {
    return existingRoutes.findIndex(er => er.name === route.name) > -1;
  }
  return false;
}

function $_hasPermission(route, permissionNames) {
  if (route.meta && route.meta.perms) {
    return permissionNames.some(perm => route.meta.perms.indexOf(perm) > -1);
  } else {
    // 没有 meta.perms 说明没有 permission 限制
    return true;
  }
}

function $_getAccessibleRoutes(routes, permissionNames, existingRoutes) {
  let accRoutes = [];
  for (let route of routes) {
    if (
      !$_routeAlreadyExist(route, existingRoutes) &&
      $_hasPermission(route, permissionNames)
    ) {
      // 注意不能改到 routes 本身
      let newRoute = { ...route };
      if (route.children && route.children.length > 0) {
        newRoute.children = $_getAccessibleRoutes(
          route.children,
          permissionNames,
          existingRoutes
        );
      }
      accRoutes.push(newRoute);
    }
  }
  return accRoutes;
}

export default {
  namespaced: true,
  state: {
    userRoutes: [],
    dynamicUserRoutes: []
  },

  getters: {
    userRoutes(state) {
      if (!state.userRoutes || state.userRoutes.length < 1) {
        return staticRoutes;
      }
      return state.userRoutes;
    },
    dynamicUserRoutes(state) {
      if (!state.dynamicUserRoutes || state.dynamicUserRoutes.length < 1) {
        return [];
      }
      return state.dynamicUserRoutes;
    }
  },

  actions: {
    generateUserRoutes({ commit }, { existingRoutes, permissionNames }) {
      // 注意不能修改到原来的 dynamicRoutes
      let newDynamicRoutes = [];
      buildDynamicRoutes().forEach(route => {
        let newRoute = { ...route };
        if (store.getters["user/customerUrl"]) {
          newRoute.path = `/${store.getters["user/customerUrl"]}${route.path}`;
        }
        newDynamicRoutes.push(newRoute);
      });
      return new Promise(resolve => {
        // 所有的有权限的 route
        let accessibleRoutes = $_getAccessibleRoutes(
          newDynamicRoutes,
          permissionNames
        );
        // 去除已存在的 route，为了防止 addRouter 的重复添加
        let nonExistAccessibleRoutes = $_getAccessibleRoutes(
          newDynamicRoutes,
          permissionNames,
          existingRoutes
        );
        commit("SET_USER_ROUTES", staticRoutes.concat(accessibleRoutes));
        commit("SET_DYNAMIC_USER_ROUTES", nonExistAccessibleRoutes);
        resolve();
      });
    },
    clearUserRoutes({ commit }) {
      commit("SET_USER_ROUTES", staticRoutes);
      commit("SET_DYNAMIC_USER_ROUTES", []);
    }
  },

  mutations: {
    SET_USER_ROUTES(state, userRoutes) {
      state.userRoutes = userRoutes;
    },
    SET_DYNAMIC_USER_ROUTES(state, dynamicUserRoutes) {
      state.dynamicUserRoutes = dynamicUserRoutes;
    }
  }
};

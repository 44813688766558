exports.clientTypeDict = {
  default: {
    value: "default",
    text: "默认"
  },
  hospital: {
    value: "hospital",
    text: "医院模式"
  },
  school: {
    value: "school",
    text: "学校模式"
  },
  judicial: {
    value: "judicial",
    text: "司法鉴定模式"
  },
  demo: {
    value: "demo",
    text: "演示模式"
  }
};

export const permissionNames = {
  inConstruction: "inconstruction",
  superAdmin: "superadmin",
  openapi: {
    read: "openapi_call"
  },
  case: {
    read: "case_read",
    edit: "case_edit",
    delete: "case_delete",
    export: "case_export",
    rpt: {
      read: "case_rpt_read",
      edit: "case_rpt_edit",
      groupRpt: "case_rpt_grouprpt",
      bulkDownload: "case_rpt_bulkdownload",
      sign: "case_rpt_sign",
      answers: "case_rpt_answers",
      publish: "case_rpt_publish",
      publishRecall: "case_rpt_publish_recall"
    },
    import: "case_import",
    allowModifyReportScore: "case_allow_modify_report_score",
    allowModifyAnswerResult: "case_allow_modify_answer_result"
  },
  person: {
    read: "person_read",
    edit: "person_edit",
    new: "person_new",
    delete: "person_delete",
    import: "person_import",
    profile: "person_profile",
    profileReport: "person_profile_report",
    groupProfileReport: "person_group_profile_report"
  },
  node: {
    read: "node_read",
    new: "node_new",
    delete: "node_delete",
    statistic: "node_statistic",
    nodeInfo: {
      read: "node_info_read",
      edit: "node_info_edit",
      qrEdit: "node_info_qredit",
      qrNew: "node_info_qrnew",
      qrDelete: "node_info_qrdelete"
    },
    testCtrl: "node_testctrl",
    rptSettings: "node_rptsettings",
    fieldConfig: "node_fieldconfig",
    identity: "node_identity",
    rptSetCoverPage: "node_rpt_set_cover_page"
  },
  sca: {
    node: {
      read: "sca_node_read",
      edit: "sca_node_edit",
      new: "sca_node_new",
      delete: "sca_node_delete",
      qrEdit: "sca_node_qredit",
      qrNew: "sca_node_qrnew",
      qrDelete: "sca_node_qrdelete"
    },
    group: {
      read: "sca_group_read",
      edit: "sca_group_edit",
      new: "sca_group_new",
      delete: "sca_group_delete",
      qrEdit: "sca_group_qredit",
      qrNew: "sca_group_qrnew",
      qrDelete: "sca_group_qrdelete"
    },
    open: {
      read: "sca_open_read",
      edit: "sca_open_edit",
      new: "sca_open_new",
      delete: "sca_open_delete",
      qrEdit: "sca_open_qredit",
      qrNew: "sca_open_qrnew",
      qrDelete: "sca_open_qrdelete"
    },
    indv: {
      read: "sca_indv_read",
      edit: "sca_indv_edit",
      new: "sca_indv_new",
      delete: "sca_indv_delete",
      qrEdit: "sca_indv_qredit",
      qrNew: "sca_indv_qrnew",
      qrDelete: "sca_indv_qrdelete"
    },
    apply: {
      read: "sca_apply_read",
      edit: "sca_apply_edit"
    },
    scaIndvSearchPersonFromDbBtn: "sca_indv_search_person_from_db_btn",
    createIndvOtherRating: "sca_create_indv_other_rating",
    createIndvJudicial: "sca_create_indv_judicial"
  },
  intervention: {
    musicrepository: {
      read: "intervention_musicrepository_read",
      edit: "intervention_musicrepository_edit",
      new: "intervention_musicrepository_new",
      delete: "intervention_musicrepository_delete"
    },
    musictherapy: {
      read: "intervention_musictherapy_read",
      edit: "intervention_musictherapy_edit",
      new: "intervention_musictherapy_new",
      delete: "intervention_musictherapy_delete"
    },
    qrcode: {
      read: "intervention_qrcode_read",
      edit: "intervention_qrcode_edit",
      new: "intervention_qrcode_new",
      delete: "intervention_qrcode_delete"
    }
  },
  lb: {
    settings: {
      read: "lb_settings_read",
      edit: "lb_settings_edit",
      new: "lb_settings_new",
      delete: "lb_settings_delete",
      editCategory: "lb_settings_editcategory",
      rptDx: "lb_settings_rptdx",
      controlEngine: "lb_settings_controlengine",
      rptTemplate: "lb_settings_rpttemplate"
    },
    alert: {
      read: "lb_alert_read",
      edit: "lb_alert_edit",
      new: "lb_alert_new"
    },
    doc: {
      read: "lb_doc_read",
      docExport: "lb_doc_export",
      quesRead: "lb_doc_quesread",
      quesExport: "lb_doc_quesexport"
    },
    groupPattern: {
      read: "lb_grouppattern_read",
      edit: "lb_grouppattern_edit",
      new: "lb_grouppattern_new",
      delete: "lb_grouppattern_delete"
    }
  },
  dashboard: {
    read: "dashboard_read",
    largeScreen: "dashboard_largescreen"
  },
  settings: {
    login: {
      user: {
        read: "settings_login_user_read",
        edit: "settings_login_user_edit",
        new: "settings_login_user_new",
        delete: "settings_login_user_delete"
      },
      role: {
        read: "settings_login_role_read",
        edit: "settings_login_role_edit",
        new: "settings_login_role_new",
        delete: "settings_login_role_delete"
      },
      expiration: {
        read: "settings_login_expiration_read",
        edit: "settings_login_expiration_edit"
      }
    },
    display: {
      edit: "settings_display_edit"
    },
    userAccount: {
      edit: "settings_user_account_edit"
    }
  },
  appoint: {
    read: "appoint_read",
    edit: "appoint_edit",
    new: "appoint_new",
    delete: "appoint_delete"
  },
  task: {
    read: "task_read",
    edit: "task_edit",
    new: "task_new",
    delete: "task_delete"
  },
  thermalPaper: {
    templateRead: "thermal_paper_template_read",
    templateEdit: "thermal_paper_template_edit",
    templateDelete: "thermal_paper_template_delete",
    print: "thermal_paper_print"
  }
};

import axios from "axios";
import router from "@/router";
import { buildUrlString } from "@/utils/urlUtils";
import { isBrowserApp, isDesktopApp } from "@/utils/appType";

if (isDesktopApp()) {
  // 从配置文件中读取 baseUrl
  window.electron.getServiceAddr();
  window.electron.onReceiveServiceAddr(serviceAddr => {
    axios.defaults.baseURL = buildUrlString(
      serviceAddr,
      "api",
      process.env.VUE_APP_API_VERSION
    );
  });
} else {
  // 浏览器模式下的生产环境，通过nginx解决跨域问题
  axios.defaults.baseURL = buildUrlString(
    process.env.VUE_APP_API_BASE_PATH,
    "api",
    process.env.VUE_APP_API_VERSION
  );
}

if (isBrowserApp()) {
  // 在浏览器的模式下，每次调用 API 自动携带 Cookie
  axios.defaults.withCredentials = true;
}

axios.defaults.timeout = process.env.VUE_APP_API_TIMEOUT;

axios.interceptors.request.use(
  request => {
    return request;
  },
  error => {
    error.message = "服务器或网络异常";
    return Promise.reject(error.message);
  }
);

axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    let errorResponse = error.response;
    let status = errorResponse.status;
    if (status === 401) {
      router.push({ name: "login" });
    } else if (status === 403) {
      router.push({ name: "nopage" });
    } else if (status === 423) {
      router.push({
        name: "noreg",
        params: { errorMsg: errorResponse.data }
      });
    }
    error.message = errorResponse.data;
    return Promise.reject(error);
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}
